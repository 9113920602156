import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import(/* webpackChunkName: "about" */ '../views/Agenda.vue')
  },
  
  {
    path: '/agenda2',
    name: 'Agenda2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Agenda2.vue')
  },

  {
    path: '/Atender',
    name: 'Atender',
    component: () => import(/* webpackChunkName: "about" */ '../views/Atender.vue')
  },

  {
    path: '/Paciente',
    name: 'Paciente',
    component: () => import(/* webpackChunkName: "about" */ '../views/Paciente.vue')
  },

  {
    path: '/print',
    name: 'print',
    component: () => import(/* webpackChunkName: "about" */ '../views/print.vue')
  },
  
  {
    path: '/guias',
    name: 'guias',
    component: () => import(/* webpackChunkName: "about" */ '../views/guias.vue')
  },
  
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },
  
  {
    path: '/Lembretes',
    name: 'Lembretes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lembretes.vue')
  },
  
  {
    path: '/tabelas',
    name: 'tabelas',
    component: () => import(/* webpackChunkName: "about" */ '../views/tabelas.vue')
  },

  {
    path: '/re',
    name: 're',
    component: () => import(/* webpackChunkName: "about" */ '../views/resultado_exame.vue')
  },

  {
    path: '/relatorios',
    name: 'relatorios',
    component: () => import(/* webpackChunkName: "about" */ '../views/relatorios.vue')
  },

  {
    path: '/recibo',
    name: 'recibo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Recibo.vue')
  },

  {
    path: '/chatbot',
    name: 'chatbot',
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatBot.vue')
  },

  {
    path: '/imprimir',
    name: 'imprimir',
    component: () => import(/* webpackChunkName: "about" */ '../views/imprimir.vue')
  },

  {
    path: '/agendamento',
    name: 'agendamento',
    component: () => import(/* webpackChunkName: "about" */ '../views/AgendamentoOnline.vue')
  },

  {
    path: '/WhatsLab',
    name: 'WhatsLab',
    component: () => import(/* webpackChunkName: "about" */ '../views/WhatsLab.vue')
  },

  {
    path: '/editar',
    name: 'editar',
    component: () => import(/* webpackChunkName: "about" */ '../views/Editar.vue')
  },

  {
    path: '/teste',
    name: 'teste',
    component: () => import(/* webpackChunkName: "about" */ '../views/Teste.vue')
  },

  {
    path: '/RelatorioComu',
    name: 'RelatorioComu',
    component: () => import(/* webpackChunkName: "about" */ '../views/RelatorioComu.vue')
  },



]

const router = new VueRouter({
  routes
})

export default router
